var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "primary-header desktop-view-header",
    style: Object.keys(_vm.themeColor).length !== 0 ? {
      background: "linear-gradient(\n            ".concat(_vm.themeColor.primaryColor.gradientColors[0].color, ",").concat(_vm.themeColor.primaryColor.color, ")")
    } : ''
  }, [_c('b-container', {
    attrs: {
      "fluid": "",
      "id": "element"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "logo text-center"
  }, [_vm.modules.home ? _c('a', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "to": "/"
    },
    on: {
      "click": function click() {
        return _vm.updateWebsiteMode('product');
      }
    }
  }, [Object.keys(_vm.themeColor).length !== 0 ? _c('img', {
    staticClass: "pt-3",
    attrs: {
      "src": "img/Logo-black.png",
      "width": "40px"
    }
  }) : _c('img', {
    staticClass: "pt-3",
    attrs: {
      "width": "40px",
      "src": "img/navbar/logo.png"
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "market-width"
  }, [_vm.$options.components['theme-market-theme'] ? [_c('theme-market-theme')] : _vm._e()], 2)])]), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('ul', {
    staticClass: "top-header-leftside"
  }, [_c('li', [_vm.$options.components['location-address'] ? [_c('location-address', {
    attrs: {
      "themeColor": _vm.themeColor
    }
  })] : _vm._e()], 2), _c('li', {
    staticStyle: {
      "margin-left": "-5px"
    }
  }, [_c('div', [_c('b-form-group', {
    staticClass: "mb-0",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var ariaDescribedby = _ref.ariaDescribedby;
        return [_c('b-form-radio-group', {
          class: Object.keys(_vm.themeColor).length !== 0 ? 'new-header-PS' : 'header-PS',
          attrs: {
            "id": "btn-radios-2",
            "options": _vm.options,
            "aria-describedby": ariaDescribedby,
            "button-variant": "outline-primary",
            "name": "radio-btn-outline",
            "buttons": ""
          },
          on: {
            "input": _vm.updateWebsiteMode
          },
          model: {
            value: _vm.selected,
            callback: function callback($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }])
  })], 1)]), _c('li', [_vm.$options.components['search-bar'] ? [_c('search-bar', {
    attrs: {
      "themeColor": _vm.themeColor
    }
  })] : _vm._e()], 2), _c('li', [_c('div', {
    staticClass: "select-style"
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.distanceOptions
    },
    on: {
      "input": _vm.fetchByDistance
    },
    model: {
      value: _vm.selectedDistance,
      callback: function callback($$v) {
        _vm.selectedDistance = $$v;
      },
      expression: "selectedDistance"
    }
  })], 1)])])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('ul', {
    staticClass: "top-header-rightside"
  }, [_c('li', [_c('div', {
    staticClass: "available-onsec"
  }, [_c('span', {
    staticClass: "available-text",
    style: Object.keys(_vm.themeColor).length !== 0 ? {
      color: "".concat(_vm.themeColor.font.color)
    } : ''
  }, [_vm._v(" Available On ")]), _c('div', {
    staticClass: "android-iphone-icon"
  }, [_c('a', {
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.teka.customer",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": "img/navbar/top_Play.png",
      "alt": "",
      "width": "30px"
    }
  })]), _c('a', {
    attrs: {
      "href": "https://apps.apple.com/in/app/teka-customer/id1635748798",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "ml-1",
    attrs: {
      "src": "img/navbar/Top_App.png",
      "alt": "",
      "width": "30px"
    }
  })])])])]), _c('li', [!_vm.$store.state['auth'].isLoggedin ? _c('b-button', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "variant": "outline-light"
    },
    on: {
      "click": function click() {
        return _this.$router.push({
          name: 'login'
        });
      }
    }
  }, [_vm._v("Login")]) : _vm._e(), _vm.$store.state['auth'].isLoggedin ? _c('div', {
    staticClass: "header-loginsec"
  }, [_c('b-dropdown', {
    class: Object.keys(_vm.themeColor).length !== 0 ? 'dropdown-toggle-new' : '',
    attrs: {
      "id": "dropdown-1"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [Object.keys(_vm.themeColor).length == 0 ? _c('b-icon', {
          attrs: {
            "icon": "person-circle"
          }
        }) : _c('img', {
          attrs: {
            "src": "img/Group.png"
          }
        }), _c('span', {
          class: Object.keys(_vm.themeColor).length !== 0 ? 'font-weight-bold text-black' : ''
        }, [_vm._v(" " + _vm._s(_vm.userInfo.name.first.substring(0, 4) + "...") + " ")])];
      },
      proxy: true
    }], null, false, 4095665019)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$router.push('/profile');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "img/navbar/profile.png"
    }
  }), _vm._v(" Profile ")]), _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        return _vm.$router.push('/wishlist');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "img/navbar/wishlist.png"
    }
  }), _vm._v(" WishList ")]), _c('b-dropdown-item', {
    on: {
      "click": _vm.myOrder
    }
  }, [_c('img', {
    attrs: {
      "src": "img/navbar/order.png"
    }
  }), _vm._v(" My Order ")]), _c('b-dropdown-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('img', {
    attrs: {
      "src": "img/navbar/logout.png"
    }
  }), _vm._v(" Log out ")])], 1)], 1) : _vm._e()], 1), _c('li', [_c('div', {
    staticClass: "header-cart"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'checkout'
      }
    }
  }, [_c('b-iconstack', {
    staticClass: "ml-4"
  }, [_c('b-icon', {
    attrs: {
      "scale": "3",
      "stacked": "",
      "icon": "circle-fill",
      "variant": "light"
    }
  }), _c('b-icon', {
    attrs: {
      "icon": "cart3",
      "scale": "2",
      "variant": "orange"
    }
  })], 1)], 1), _vm.cartData.length > 0 ? _c('h6', {
    staticClass: "checkout-notification"
  }, [_vm._v(" " + _vm._s(Array.isArray(_vm.cartData) && _vm.cartData.length > 0 ? _vm.cartData.length : "") + " ")]) : _vm._e()], 1)])])])], 1)], 1)], 1), _c('div', {
    staticClass: "primary-header mobile-view-header",
    style: Object.keys(_vm.themeColor).length !== 0 ? {
      background: "linear-gradient(\n            ".concat(_vm.themeColor.primaryColor.gradientColors[0].color, ",").concat(_vm.themeColor.primaryColor.color, ")")
    } : ''
  }, [_c('b-container', {
    attrs: {
      "fluid": "",
      "id": "element"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mt-2"
  }, [_c('div', {
    staticClass: "location-mobile-view"
  }, [_vm.$options.components['location-address'] ? [_c('location-address', {
    attrs: {
      "modalId": "popover2"
    }
  })] : _vm._e()], 2), _c('div', {
    staticClass: "mb-0"
  }, [_c('b-navbar-nav', {
    staticClass: "top-header-rightside"
  }, [_c('b-nav-form', [_c('div', {
    staticClass: "header-cart"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'checkout'
      }
    }
  }, [_c('b-iconstack', {
    staticClass: "ml-4"
  }, [_c('b-icon', {
    attrs: {
      "scale": "2.5",
      "stacked": "",
      "icon": "circle-fill",
      "variant": "light"
    }
  }), _c('b-icon', {
    attrs: {
      "icon": "cart3",
      "scale": "1.5",
      "variant": "orange"
    }
  })], 1)], 1), _vm.cartData.length > 0 ? _c('h6', {
    staticClass: "checkout-notification"
  }, [_vm._v(" " + _vm._s(Array.isArray(_vm.cartData) && _vm.cartData.length > 0 ? _vm.cartData.length : "") + " ")]) : _vm._e()], 1)])], 1)], 1)])]), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "search-mobile-view"
  }, [_vm.$options.components['search-bar'] ? [_c('search-bar')] : _vm._e()], 2)])], 1)], 1), _c('b-container', {
    staticClass: "p-0 margin-top-98 mobile-hide-margin",
    attrs: {
      "fluid": ""
    }
  }, [_vm._t("default")], 2), _c('div', {
    attrs: {
      "id": "helpContent"
    }
  }, [_c('div', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#element',
      expression: "'#element'"
    }],
    staticClass: "tap-top top-cls"
  }, [_c('div', [_c('b-icon', {
    attrs: {
      "icon": "chevron-double-up",
      "aria-hidden": "true"
    }
  })], 1)]), this.$route.path !== "/" ? _c('div', [_c('div', {
    staticClass: "tap-top2 top-cls2",
    on: {
      "click": function click($event) {
        return _vm.clickFunc();
      }
    }
  }, [_c('div', {
    staticClass: "margin-top-2"
  }, [_c('b-icon', {
    attrs: {
      "icon": "telephone-fill",
      "aria-hidden": "true"
    }
  })], 1)]), _vm.clickHelp ? _c('div', {
    staticClass: "tap-top3 top-cls3"
  }, [_c('div', [_c('div', {
    staticClass: "mt-n1"
  }, [_vm._v("Helpline")]), _c('span', [_c('a', {
    staticClass: "text-white",
    attrs: {
      "href": "tel:+919550265720"
    }
  }, [_vm._v(_vm._s(_vm.partnerInfo.preferences.delivery.deliveryOwner.phone))])])])]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "bg-orange mt-4 footer-part"
  }, [_c('b-container', {
    staticClass: "bv-example-row text-white"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "mt-5"
  }, [_c('h5', {
    staticClass: "my-4"
  }, [_vm._v("Get To Know Us")]), _c('div', {
    staticClass: "knowsus"
  }, [_vm.modules.home ? _c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("Home")]) : _vm._e(), _vm.modules.about ? _c('router-link', {
    attrs: {
      "to": "/about"
    }
  }, [_vm._v("About Us")]) : _vm._e(), _vm.modules.returnRefundPolicy ? _c('router-link', {
    attrs: {
      "to": "/how_it_works"
    }
  }, [_vm._v("How it Works")]) : _vm._e(), _vm.modules.returnRefundPolicy ? _c('router-link', {
    attrs: {
      "to": "/teka_delivery_policy"
    }
  }, [_vm._v("Teka Delivery Policy ")]) : _vm._e(), _vm.modules.privacyPolicy ? _c('router-link', {
    attrs: {
      "to": "/cancellation_and_refund_policies"
    }
  }, [_vm._v("Cancellation & Refund Policies")]) : _vm._e()], 1)])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "mt-5"
  }, [_c('h5', {
    staticClass: "my-4"
  }, [_vm._v("Connect With Us")]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.facebook.com/TekaTime-105852398754087"
    }
  }, [_c('img', {
    staticClass: "mr-2 height",
    attrs: {
      "src": "img/footer/facebook.png",
      "alt": ""
    }
  })]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.instagram.com/tekatime/"
    }
  }, [_c('img', {
    staticClass: "mr-2 height",
    attrs: {
      "src": "img/footer/instagram.png",
      "alt": ""
    }
  })]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.linkedin.com/in/teka-time-524539236/"
    }
  }, [_c('img', {
    staticClass: "mr-2 height",
    attrs: {
      "src": "img/footer/linkedin.png",
      "alt": ""
    }
  })]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://twitter.com/teka_time"
    }
  }, [_c('img', {
    staticClass: "height",
    attrs: {
      "src": "img/footer/twitter.png",
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "knowsus mt-3"
  }, [_vm.modules.contact ? _c('router-link', {
    attrs: {
      "to": "/contact"
    }
  }, [_vm._v("Contact Us")]) : _vm._e(), _vm.modules.termsConditions ? _c('router-link', {
    attrs: {
      "to": "/terms-and-conditions"
    }
  }, [_vm._v("Terms and Conditions")]) : _vm._e(), _vm.modules.privacyPolicy ? _c('router-link', {
    attrs: {
      "to": "/privacy-policy"
    }
  }, [_vm._v("Privacy Policy")]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "mt-5 text-right payment-method-footer"
  }, [_c('h5', {
    staticClass: "my-4"
  }, [_vm._v("Now We Are Available On")]), _c('ul', {
    staticClass: "playstore-div"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "https://apps.apple.com/in/app/teka-customer/id1635748798",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "ml-2",
    attrs: {
      "src": "img/navbar/bottom_app_store.png",
      "alt": "",
      "width": "160px"
    }
  })]), _c('a', {
    attrs: {
      "href": "https://play.google.com/store/apps/details?id=com.teka.customer",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "ml-2",
    attrs: {
      "src": "img/navbar/playstore-1.png",
      "alt": "",
      "width": "160px"
    }
  })])])]), _c('div', {
    staticClass: "footer-makeinindia"
  }, [_c('b-img', {
    attrs: {
      "src": "img/footer/Make-in-India-Logo.jpg",
      "width": "180"
    }
  })], 1)])])], 1), _c('hr', {
    staticClass: "mt-2 hr-1"
  }), _c('b-row', [_c('b-col', {
    staticClass: "text-center my-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "mx-3 copyright-sec"
  }, [_vm._v(" Copyright © 2022 Teka Powered by "), _c('a', {
    attrs: {
      "id": "link-footer",
      "href": "https://nipige.com/",
      "target": "_blank"
    }
  }, [_vm._v("nipige")]), _vm._v(". ")])])], 1)], 1)], 1), _c('div', {
    staticClass: "teka-mobile-menu"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/small_teka_logo.png",
      "width": "16px",
      "alt": ""
    }
  }), _vm._v(" Home ")]), _c('router-link', {
    attrs: {
      "to": "/store"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "shop"
    }
  }), _vm._v("Store ")], 1), _c('router-link', {
    attrs: {
      "to": "/my-order"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "cart4"
    }
  }), _vm._v("My Orders ")], 1), _c('router-link', {
    attrs: {
      "to": "/profile"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "person-circle"
    }
  }), _vm._v("Account ")], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
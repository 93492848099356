import productDataFilter from "../../shared/productDataFilter";
//import moment from "moment";
import {
  fetchCartItem,
  getPartnerInfo,
  addToCartService,
  removeCartService,
  finalCheckout,
  healthianCheckout,
  orderValidation,
  finalTransactionBenefits,
  fetchCoupon,
  createOrder,
  fetchBankDetails,
  clearCart,
} from "./checkout.services";
export default {
  namespaced: true,
  state: {
    cartData: [],
    checkoutData: {},
    createdOrderData: [],
    partnerInfo: [],
    selfPick: false,
    allowPickupFromShop: "",
    date: new Date(),
    isSelfPickUp: false,
    transactionBenefits: {},
    couponList: [],
    couponLoading: true,
    checkoutLoading: true,
    selectedCoupon: {},
    paymentMethodClick: "COD",
  },
  getters: {},
  actions: {
    updatePaymentClick(context, payload) {
      context.state.paymentMethodClick = payload;
    },
    async addToCartBulk(context, payload) {
      await addToCartService({
        item: payload._id,
        quantity: payload.qty,
      });
    },
    async getAccountDetails(context, payload) {
      let res = await fetchBankDetails(payload);
      return res;
    },
    async clearCart(context) {
      try {
        let clr = await clearCart();
        // context.state.cartData = [];
        context.commit("setCartData", []);
        context.dispatch("checkoutFinal");
        return clr;
      } catch (err) {
        return err;
      }
    },

    async removeItem(context, { cartItem, callApi }) {
      //   if (this.state.auth.isLoggedin) {
      if (callApi)
        await removeCartService({
          item: cartItem._id,
          quantity: cartItem.qty,
        });

      const filteredData = this.state?.checkout?.cartData.filter(
        (item) => item._id !== cartItem._id
      );
      await context.commit("setCartData", filteredData);
    },
    async createOrderAction(context) {
      try {
        // context.state.checkoutLoading = true;
        let payloadData = {
          customerDetails: {
            orderAmount: context.state.checkoutData.totalPrice,
            customerName: this.state.auth.userInfo.authentication.userName,
            customerPhone: this.state.auth.userInfo.authentication.phone,
            customerEmail: this.state.auth.userInfo.authentication.email,
          },
          orderDetails: {
            paymentType: context.state.checkoutData.payment.type,
            deliveryAddress: {
              address: this.state.address.defaultAddress,
              loc: this.state.address.defaultAddress.location,
            },
            items: context.state.checkoutData.items
              ? context.state.checkoutData.items
              : [],
          },
          txData: {
            orderId: context.state.checkoutData.payment.orderId,
            referenceId: "",
            txStatus: "",
            txMsg: "",
            txTime: "",
            signature: "",
            PaymentServiceName: "",
          },
          createdAt: context.state.checkoutData.createdAt,
          payments: context.state.checkoutData.payments,
          payment: context.state.checkoutData.payment,
          orderHash: context.state.checkoutData.hash,
          totalPriceBeforeTax: context.state.checkoutData.totalPriceBeforeTax,
          isNew: true,
        };
        let ordr = await createOrder(payloadData);
        // await clearCart();
        // context.state.cartData = [];
        context.commit("setCreatedOrderData", ordr.data.response);
        console.log(ordr, "created order");
        return ordr;
      } catch (err) {
        return err;
      }
    },
    async validateOrderAction(context, payload) {
      try {
        let validation = await orderValidation(payload);
        await clearCart();
        context.state.cartData = [];
        return validation;
      } catch (error) {
        return error;
      }
    },
    async fetchCouponList(context) {
      context.state.couponLoading = true;
      let res = await fetchCoupon();
      context.state.couponLoading = false;
      context.commit("setCopunList", res?.data?.response);
    },
    fetchCartData: async (context, payload) => {
      let result = await fetchCartItem(payload);
      try {
        result = productDataFilter.methods.filterProductData(
          result.data.response?.items
        );
      } catch (err) {
        console.log(err);
      }
      // experiemt code
      let cartData = context.state.cartData;
      let resStore = result;
      if (Array.isArray(cartData) && cartData.length > 0) {
        if (Array.isArray(result) && result.length > 0) {
          await cartData.forEach(async (el) => {
            if (resStore.some((i) => i._id == el._id)) {
              await resStore.forEach(async (resEl) => {
                if (resEl._id == el._id) {
                  let qty = el.qty - resEl.qty;
                  if (qty > 0) {
                    await addToCartService({
                      item: el._id,
                      quantity: qty,
                    });
                  } else if (qty < 0) {
                    await removeCartService({
                      item: el._id,
                      quantity: Math.abs(qty),
                    });
                  }
                  resEl.qty = el.qty;
                }
              });
              context.commit("setCartData", result);
            } else {
              await addToCartService({
                item: el._id,
                quantity: el.qty,
              });
              result.push(el);
              context.commit("setCartData", result);
              console.log("gdafgdaf else case new item add", result);
            }
          });
        } else {
          await cartData.forEach(async (el) => {
            await addToCartService({
              item: el._id,
              quantity: el.qty,
            });
          });
          result = cartData;
          context.commit("setCartData", result);
        }
      }
      //   end
      context.commit("setCartData", result);
    },
    async increaseCartQty(context, { cartItem, callApi }) {
      //   if (this.state.auth.isLoggedin) {
      if (callApi)
        await addToCartService({
          item: cartItem._id,
          quantity: 1,
        });
      // await context.dispatch(
      //   "checkout/fetchCartData",
      //   {
      //     shipFrom: "IN",
      //     shipTo: "IN",
      //     country: "IN",
      //   },
      //   { root: true }
      // );
      //   } else {
      if (
        this.state?.checkout?.cartData.some((item) => item._id == cartItem._id)
      ) {
        context.commit("setIncreaseQtyToStore", cartItem);
      } else {
        cartItem.qty = 1;
        this.state.checkout.cartData.push(cartItem);
      }
      //}
    },
    async decreaseCartQty(context, { cartItem, callApi }) {
      //   if (this.state.auth.isLoggedin) {
      if (callApi)
        await removeCartService({
          item: cartItem._id,
          quantity: 1,
        });
      // await context.dispatch(
      //   "checkout/fetchCartData",
      //   {
      //     shipFrom: "IN",
      //     shipTo: "IN",
      //     country: "IN",
      //   },
      //   { root: true }
      // );
      //   } else {
      if (
        this.state?.checkout?.cartData.some((item) => item._id == cartItem._id)
      ) {
        await context.commit("setDecreaseQtyToStore", cartItem);
        await context.commit("setCartData", this.state.checkout.cartData);
      }
      // }
    },
    async checkoutFinal(context, payload) {
      let addr = this.state.address.defaultAddress;
      try {
        if (addr.city) {
          let passPayload = {
            location: {
              long: addr?.location?.coordinates[1],
              lat: addr?.location?.coordinates[0],
              zip: addr?.zip,
              state: addr?.state,
              city: addr?.city,
            },
            checkout: {
              type: context.state.paymentMethodClick,
              customerNo: this.state.auth.userInfo.customerNo,
              userEmail: this.state.auth.userInfo.authentication.email,
              userPhone: this.state.auth.userInfo.authentication.phone,
            },
            isSelfPickUp: context.state.isSelfPickUp,
            pickUpTime: context.state.date,
          };
          let coupon = this.state.checkout.selectedCoupon;
          if (coupon.promoCode) {
            passPayload.couponCode = coupon.promoCode;
          }
          if (context.state.paymentMethodClick == "CASHFREE") {
            passPayload.isMobile = false;
          }
          context.state.checkoutLoading = true;
          if (payload !== undefined) {
            await healthianCheckout(payload).then((res) => {
              context.commit("setCheckoutData", res.data);
              context.state.checkoutLoading = false;
            });
          } else {
            await finalCheckout(passPayload)
              .then((res) => {
                context.commit("setCheckoutData", res.data);
                context.state.checkoutLoading = false;
              })
              .catch((err) => {
                console.log("err", err);
                context.state.checkoutLoading = "errorAddress";
              });
          }

          // console.log("res", res);
          //context.state.checkoutLoading = false;
        } else {
          context.state.checkoutLoading = "errorAddress";
        }
      } catch {
        context.state.checkoutLoading = "errorAddress";
      }
    },

    async transactionBenefitsFinal(context) {
      try {
        let payload = {
          orderAmount:
            this.state?.checkout?.checkoutData?.listPrice ||
            this.state?.checkout?.checkoutData?.items[0].price ||
            "",
          provider: this.state?.checkout?.cartData[0]?.provider,
        };
        await finalTransactionBenefits(payload)
          .then((res) => {
            context.commit("setTransactionBenefits", res.data.response);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } catch (err) {
        return err;
      }
    },
    fetchPartnerInfo: async (context, payload) => {
      let res = await getPartnerInfo(payload);
      context.commit("setPartnerInfo", res);
    },
  },
  mutations: {
    setPickupStatus(state, payload) {
      const data = payload.selected == "true" ? true : false;
      state.isSelfPickUp = data;
      state.date = payload.pickup;
    },
    updateselectedCoupon(state, payload) {
      state.selectedCoupon = payload;
    },
    async setCopunList(state, payload) {
      let filteredCoupon = [];

      await payload.forEach((el) => {
        // !el.isDeleted && el.isApproved
        //    moment().isSameOrBefore(el?.promoRules?.endDate)
        if (!el.isDeleted && el.isApproved) {
          let xx = el.promoRules?.wallet?.amountType?.key || "";
          if (!["1102001", "1103001", "1104001"].includes(xx)) {
            filteredCoupon.push(el);
          }
        }
      });
      state.selectedCoupon = filteredCoupon.length > 0 ? filteredCoupon[0] : {};
      state.couponList = filteredCoupon;
    },
    setCheckoutData(state, payload) {
      state.checkoutData = payload;
    },
    setCreatedOrderData(state, data) {
      state.createdOrderData = data;
    },
    setTransactionBenefits(state, payload) {
      state.transactionBenefits = payload;
    },
    setCartData: async (state, payload) => {
      let filterData = [];
      state.cartData = [];
      await payload.forEach((element) => {
        if (element?.qty && element.qty > 0) {
          filterData.push(element);
        }
      });
      state.cartData = filterData;
    },
    setPartnerInfo: (state, data) => {
      state.allowPickupFromShop = data.preferences.delivery.allowPickupFromShop;
      state.partnerInfo = data.preferences.paymentMethod;
    },
    async setIncreaseQtyToStore(state, payload) {
      let data = this.state?.checkout?.cartData || [];
      await data.map((el) => {
        if (el._id == payload._id) {
          el.qty = parseInt(el.qty);
          //   let price =
          //     el.unitDiscountedPrice > 0 ? el.unitDiscountedPrice : el.unitPrice;
          el.qty += 1;
          el.unitAmount += el.unitPrice;
        }
      });
      state.cartData = [];
      state.cartData = data;
    },

    async setDecreaseQtyToStore(state, payload) {
      let data = this.state?.checkout?.cartData || [];
      await data.map((el) => {
        if (el._id == payload._id) {
          el.qty = parseInt(el.qty);
          //   let price =
          //     el.unitDiscountedPrice > 0 ? el.unitDiscountedPrice : el.unitPrice;
          el.qty -= 1;
          el.unitAmount -= el.unitPrice;
        }
      });
      state.cartData = [];
      state.cartData = data;
    },
  },
};

import moduleConfig from "./health_care.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./health_care.module"),
    children: [
      {
        path: ':category',
        name: 'health-care',
        component: () => import("./views/health_care.vue"),
      },
      {
        path: ":items",
        name: "health-care-listing",
        component: () => import("./views/health_careListing.vue"),
      },
      {
        path: '/orderPrescription',
        name: 'order-prescription',
        component: () => import("./views/order_prescription.vue"),
      },
      {
        path: '/pharmacyListing',
        name: 'pharmacy-listing',
        component: () => import("./views/pharmacyListing.vue"),
      },
      {
        path: ':profile',
        name: 'package_details',
        component: () => import("./views/package_details.vue"),

      },
      {
        path: ':profile/book',
        name: 'package_book',
        component: () => import("./views/package_book.vue"),
      },
      {
        path: ':profile/book/healthCare_payment',
        name: 'healthCare_payment',
        component: () => import("./views/healthCare_payment.vue"),
      },
      {
        path: '/quote',
        name: 'request-quote',
        component: () => import("./views/requestQuote.vue"),
      },
      {
        path: '/pharmacyShop/:id',
        name: 'pharmacy-Shop',
        component: () => import("./views/pharmacyShop.vue"),
      },
    ],
  },
];


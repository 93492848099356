export default {
  key: "theme",
  basePath: "/theme",
  exclude: false,

  components: [
    {
      name: "market-theme",
      component: () => import("./components/theme"),
    },
  ],
};

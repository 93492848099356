import {
  loginApi,
  createUser,
  loginWithPwd,
  getTenantConfig,
} from "./auth.services";
const userInfo = JSON.parse(localStorage.getItem("eparisheva:userInfo"));
const token = localStorage.getItem("eparisheva:access_token");
export default {
  namespaced: true,
  state: {
    token: "",
    userInfo: userInfo ? userInfo : {},
    isLoggedin: token ? true : false,
  },
  mutations: {
    loginToken(state, payload) {
      state.token = payload;
    },
    setUserData(state, payload) {
      state.isLoggedin = true;
      state.userInfo = payload;
    },
    resetUser(state, payload) {
      console.log(payload);
      state.isLoggedin = false;
      state.userInfo = {};
      state.token = "";
      this.state.checkout.cartData = [];
    },
  },
  actions: {
    async createNewUser(context, payload) {
      let res = await createUser(payload);
      return res;
    },

    async getTenantConfigDetails() {
      let res = await getTenantConfig();
      return res;
    },

    async loginWithPassword(context, payload) {
      let res = await loginWithPwd(payload);
      return res;
    },

    loginToken: (context, payload) => {
      context.commit("loginToken", payload);
    },
    setUserData: (context, payload) => {
      context.commit("setUserData", payload);
    },
    resetUser: (context, payload) => {
      context.commit("resetUser", payload);
    },
    async callLogin(context, payload) {
      try {
        let res = await loginApi(payload);
        return res;
      } catch (err) {
        return err;
      }
    },
  },
};

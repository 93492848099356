import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./registerModule";
import "./_styles.scss";
import * as VueGoogleMaps from "vue2-google-maps";
import vuetify from "./plugins/vuetify";
import "vuetify/dist/vuetify.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
const VueScrollTo = require("vue-scrollto");
import Toasted from "vue-toasted";
import "vue-search-select/dist/VueSearchSelect.css";

Vue.use(VueScrollTo);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDHCbgMAU-KzqFmI8LtrG4DWepIm7mAJJM",
    libraries: "places",
  },
});

let toastOption = {
  theme: "toasted-primary",
  position: "top-right",
  duration: 3000,
  singleton: true,
  action: {
    text: "Cancel",
    // onClick: (e: any, toastObject: any) => {
    //   toastObject.goAway(0);
    // }
  },
};

Vue.use(VueSweetalert2);
Vue.config.productionTip = false;

Vue.use(
  VueAxios,
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);

Vue.axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("eparisheva:access_token");
    //  const token = process.env.VUE_APP_TOKEN;
    if (token != null) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["x-encrypted-key"] = process.env.VUE_APP_TENANT;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyBDMqr3DZtvXERxVS69OIjiFMMPUkuAEls",
  authDomain: "eparisheva-001.firebaseapp.com",
  projectId: "eparisheva-001",
  storageBucket: "eparisheva-001.appspot.com",
  messagingSenderId: "220961113659",
  appId: "1:220961113659:web:bfa4f0ac94178e243d7e1e",
  measurementId: "G-DN3M5FZV5V",
};

// Initialize Firebase
initializeApp(firebaseConfig);
Vue.use(Toasted, toastOption);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vuetify);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

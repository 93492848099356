import vue from "vue";

export const fetchElectricityCategory = async (payload) => {
  const res = await vue.axios.get(
    `/checkout/checkout/v2/mobikwik/list-operators?category=${payload}`
  );
  return res.data.response;
};

export const transactionBenefits = async (payload) =>
  await vue.axios.post("/order/api/v1/order/reward-estimate", payload);

export const fetchCategoryData = async (payload) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/tree-public?market=${payload}`
  );
  return res.data.response;
};
export const fetchDTHList = async (payload) => {
  const res = await vue.axios.get(
    `/checkout/checkout/v2/mobikwik/list-operators?category=${payload}`
  );
  return res.data.response;
};

export const fetchProductType = async (payload) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/tree-public?market=${payload}`
  );
  return res;
};

export const fetchProduct = async (payload) => {
  const res = await vue.axios.post(`/search/category/search/`, payload);
  return res;
};

export const fetchBill = async (payload) => {
  const res = await vue.axios.post(
    `/checkout/checkout/v2/mobikwik/view-bill`,
    payload
  );
  return res;
};

export const fetchCheckoutDataMobile = async (payload) => {
  const res = await vue.axios.post(`/checkout/appointment/book`, payload);

  return res;
};

export const fetchMobileData = async (payload) => {
  const res = await vue.axios.get(
    `/checkout/checkout/v2/mobikwik/recharge-details?phone=${payload}`
  );
  return res;
};
export const allPlans = async (payload) => {
  const result = await vue.axios.post(
    "/checkout/checkout/v2/mobikwik/all-plans",
    payload
  );
  return result;
};
export const viewBill = async (payload) => {
  const result = await vue.axios.post(
    "/checkout/checkout/v2/mobikwik/view-bill",
    payload
  );
  return result;
};
export const PostpaidOperatorid = async (payload) => {
  const res = await vue.axios.post(
    "/checkout/checkout/v2/mobikwik/get-operator",
    payload
  );
  return res;
};
export const finalTransactionBenefits = async (payload) => {
  const res = await vue.axios.post(
    "/order/api/v1/order/reward-estimate",
    payload
  );
  return res;
};

export const createOrderApi = async (payload) => {
  const res = await vue.axios.post("/order/api/v2/order/create", payload);
  return res;
}

export const createOrdervalidateApi = async (payload) => {
  const res = await vue.axios.post("/order/api/v2/order/verify", payload);
  return res;
}


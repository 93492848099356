<template>
  <div class="login-bg pt-4 pb-4">
    <div class="account-pages">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 text-right">
            <div class="login-left-side pt-3">
              <h2 v-bind:style="{ color: '#0b4990' }">
                One App for all your needs!
              </h2>
              <h3 v-bind:style="{ color: '#0b4990' }">
                Enjoy your Shopping with us!
              </h3>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mt-4 rounded-lg register-sec">
              <!-- Logo -->
              <div
                class="card-header pt-2 pb-2 text-center bg-black rounded-top"
              >
                <div class="row">
                  <div class="col-lg-3">
                    <b-img
                      src="/img/login/logo_icon.png"
                      width="55px"
                      height="55px"
                      fluid
                      class="login-logo-icon"
                    ></b-img>
                    <!-- <img src="/img/logo_icon.png"/> -->
                  </div>
                  <div class="col-lg-7">
                    <b-img
                      src="/img/login/text logo.png"
                      width="150px"
                      height="auto"
                      class="mt-2 teka-login-logo"
                    ></b-img>
                    <!-- <img src="/img/e-পরিষেবা_text logo.png"/> -->
                  </div>
                </div>
                <!-- <span class="text-white font-weight-bold h2">E parisheva</span> -->
              </div>
              <div class="card-body p-4">
                <!-- <form @submit.prevent="onSubmit"> -->
                <div v-if="!otpKey" class="form-group">
                  <v-text-field
                    v-model="phone"
                    placeholder="Phone Number"
                  ></v-text-field>
                  <p v-if="!isPhoneValid" class="is-valid-phone">
                    Enter a valid phone number
                  </p>
                </div>
                <!-- <div class="form-group">
                    <v-text-field
                      v-model="password"
                      type="password"
                      placeholder="Enter Password"
                    ></v-text-field>
                  </div> -->
                <div v-if="!otpKey" class="form-group mb-0">
                  <v-text-field
                    v-model="tid"
                    placeholder="Referral ID"
                  ></v-text-field>
                  <p
                    v-if="tid == '' ? false : !isTidValid"
                    class="is-valid-phone"
                  >
                    Enter a valid Referral number
                  </p>
                </div>
                <div v-if="!otpKey" class="text-right">
                  <a v-bind:style="{ color: '#272727' }"
                    >Don't Have Referral TID?
                    <span
                      @click="
                        () => {
                          this.getTid();
                        }
                      "
                      style="color: #007bff; cursor: pointer"
                      >Get Now</span
                    ></a
                  >
                </div>
                <div v-if="otpKey" class="row justify-content-md-center">
                  <div class="text-center">
                    <p :style="{ color: otpMsg.key }">
                      {{ otpMsg.value }}
                    </p>
                    <form action="" class="mt-4 mb-4">
                      <input
                        v-model="otpInput1"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        class="otp border"
                        type="text"
                        maxlength="1"
                      />
                      <input
                        v-model="otpInput2"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        class="otp border"
                        type="text"
                        maxlength="1"
                      />
                      <input
                        v-model="otpInput3"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        class="otp border"
                        type="text"
                        maxlength="1"
                      />
                      <input
                        v-model="otpInput4"
                        class="otp border"
                        v-on:keyup="$event.target.nextElementSibling.focus()"
                        type="text"
                        maxlength="1"
                      />
                    </form>
                  </div>
                </div>

                <div v-if="otpKey && !isLoading" style="text-align: center">
                  Not Recived Your Code?
                  <b v-if="countDownObj.countDownVar > 0"
                    ><a id="resendCode" class="modal-title-otp-timer"
                      >Resend Code in {{ countDownObj.msg }}
                    </a>
                  </b>
                  <b v-else class="modal-title-otp"
                    ><a id="resendCode" @click="() => this.login()"
                      >Resend Code Otp
                    </a>
                  </b>
                </div>

                <div v-if="!otpKey" class="text-left">
                  <b-form-checkbox
                    id="checkbox-signin"
                    value="true"
                    v-model="selected"
                    rules="required"
                  >
                    I Accepted
                    <router-link to="/terms-and-conditions"
                      >Terms & Conditions</router-link
                    >
                  </b-form-checkbox>
                </div>
                <div class="form-group pt-4 text-center">
                  <template v-if="!isLoading">
                    <b-button
                      v-if="!otpKey"
                      class="btn-primary"
                      size="lg"
                      type="submit"
                      :disabled="
                        !isPhoneValid ||
                        !isTidValid ||
                        // password.length < 6 ||
                        tid == '' ||
                        !selected
                      "
                      @click="() => this.login()"
                      >Create An Account</b-button
                    >
                    <b-button
                      v-if="otpKey"
                      :disabled="!isPhoneValid"
                      class="primary-btn"
                      size="lg"
                      @click="() => this.loginOtp()"
                      >Sign up with OTP</b-button
                    >
                  </template>
                  <template v-if="isLoading">
                    <b-spinner
                      v-for="i in 3"
                      :key="i"
                      class="mr-1"
                      small
                      label="Small Spinner"
                      type="grow"
                    ></b-spinner>
                  </template>
                </div>
                <!-- </form> -->
                <div class="text-center pt-1">
                  <p>
                    Already Have An Account?
                    <router-link :to="{ name: 'login' }">Login</router-link>
                  </p>
                </div>
                <div>
                  <p class="pt-2" style="font-size: 13px">
                    By continuing, you agree to Teka
                    <router-link to="/terms-and-conditions"
                      >Terms & Conditions</router-link
                    >
                    of Use and
                    <router-link to="/privacy-policy">
                      Privacy Policy</router-link
                    >
                  </p>
                </div>
              </div>
              <!-- end card-body -->
            </div>
            <!-- end card -->
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      countDownObj: {
        countDownVar: 0,
        msg: "",
      },
      phone: "",
      // password: "",
      otpMsg: { value: "", key: "" },
      otpKey: false,
      otpInput1: "",
      otpInput2: "",
      otpInput3: "",
      otpInput4: "",
      tid: "",
      selected: "",
      acceptTerms: "",
      isLoading: false,
    };
  },
  computed: {
    isPhoneValid() {
      if (this.phone == "") return false;
      var isPhone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      if (isPhone.test(this.phone)) {
        return true;
      } else {
        return false;
      }
    },
    isTidValid() {
      if (this.tid == "") return false;
      if (this.tid.startsWith("TID")) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    timerFunc() {
      this.countDownObj.countDownVar = 60;
      this.countDownObj.msg = `00 : ${this.countDownObj.countDownVar}`;
      clearInterval(countDownIntervalFunc);
      const countDownIntervalFunc = setInterval(() => {
        this.countDownObj.countDownVar--;
        if (this.countDownObj.countDownVar < 10) {
          this.countDownObj.msg = `00 : 0${this.countDownObj.countDownVar}`;
        } else if (this.countDownObj.countDownVar > 10) {
          this.countDownObj.msg = `00 : ${this.countDownObj.countDownVar}`;
        }
        if (this.countDownObj.countDownVar < 0) {
          clearInterval(countDownIntervalFunc);
        }
      }, 1000);
    },
    async getTid() {
      try {
        if (this.tid == "") {
          try {
            let tenantConfigRes = await this.$store.dispatch(
              "auth/getTenantConfigDetails"
            );
            if (tenantConfigRes.status == 200) {
              this.tid = tenantConfigRes.data?.response?.defaultReferralCode;
            } else {
              this.tid = this.phone;
            }
          } catch {
            this.tid = this.phone;
          }
        }
      } catch {
        this.tid = this.phone;
      }
    },

    async loginOtp() {
      this.isLoading = true;
      const payload = {};
      payload.mobile = this.phone;
      payload.otp =
        this.otpInput1 + this.otpInput2 + this.otpInput3 + this.otpInput4;
      await this.$store
        .dispatch("auth/callLogin", payload)
        .then(async (result) => {
          const token = await result.data.response.token;
          localStorage.setItem("eparisheva:access_token", token);
          localStorage.setItem(
            "eparisheva:userInfo",
            JSON.stringify(result.data.response.user)
          );
          this.$store.dispatch("auth/loginToken", token);
          this.$store.dispatch("auth/setUserData", result.data.response.user);
          this.$store.commit(
            "address/setDeliveryAddress",
            result.data.response.user.addresses
          );
          setTimeout(() => {
            this.$router.push("/");
            this.isLoading = false;
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
          this.otpMsg = { value: "Invalid OTP", key: "red" };
          this.otpInput1 =
            this.otpInput2 =
            this.otpInput3 =
            this.otpInput4 =
              "";
          this.isLoading = false;
        });
    },

    async login() {
      this.isLoading = true;
      try {
        const payload = {};
        payload.mobile = this.phone;
        payload.referralCustomerNo = this.tid;
        console.log("payload", payload);

        const result = await this.$store.dispatch("auth/callLogin", payload);
        if (result.response) {
          let msg = result.response.data.message;
          this.$swal({
            text: msg + " please Login",
            type: "warning",
          });
        }
        if (result.data.status == 1) {
          this.otpKey = true;
          this.otpMsg = { value: result.data.message, key: "green" };
          this.timerFunc();
          this.$store.state["home"].showCashbackPopup = true;
        } else {
          this.otpMsg = { value: "can not send otp", key: "red" };
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
      sessionStorage.removeItem("referralid");
    },

    // async onRegister() {
    //   try {
    //     this.isLoading = true;

    //     let email = "sha256" + Date.now() + "@tekatime.com";

    //     const payload = {
    //       name: {
    //         first: this.phone.toString(),
    //         last: this.phone.toString(),
    //       },
    //       authentication: {
    //         securityQuestions: {
    //           question: "Default",
    //           answer: "Default",
    //         },
    //         userName: this.phone.toString(),
    //         phone: this.phone.toString(),
    //         email: email,
    //         password: "thispassword",
    //       },
    //       referralCustomerNo: this.tid,
    //     };
    //       console.log(payload,"Payload result")
    //       const result = await this.$store.dispatch(
    //         "auth/createNewUser",
    //       payload
    //     );
    //     console.log(result,"result")
    //     if (result) {
    //       this.login()
    //       // this.isLoading = false;
    //       // this.$swal({
    //       //   type: "success",
    //       //   title: "Well Done !",
    //       //   text: "Registration successfully completed!",
    //       // });
    //       sessionStorage.removeItem("referralid");
    //       // setTimeout(() => {
    //       //   this.$router.push("/login");
    //       // }, 1500);
    //     }
    //   } catch (e) {
    //     this.isLoading = false;

    //     this.$swal({
    //       type: "error",
    //       title: "Error",
    //       text: "The Phone Number is already exist",
    //     });
    //   }
    // },
  },
  mounted() {
    this.tid =
      this.$route.query?.referralid ||
      sessionStorage.getItem("referralid") ||
      "";
    sessionStorage.setItem("referralid", this.tid);
  },
};
</script>
<style scoped>
.login-bg {
  background-image: url("/img/login/login_bg_image.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
}
.modal-title-otp-timer {
  text-decoration: none !important;
  color: gray !important;
}
.btn-primary {
  color: #fff;
  padding: 0.5rem 5rem;
}

.otp {
  margin-right: 10px;
}

.border {
  border: 1px solid #000;
}

.modal-title-otp a {
  color: #0056b3;
}

.modal-title-otp a:hover {
  text-decoration: underline !important;
  color: #0056b3;
}

form input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
}

.is-valid-phone {
  font-size: 14px;
  margin-top: 0;
  margin-left: 0;
  color: red;
}
@media (max-width: 768px) {
  .login-left-side.pt-3 {
    padding-top: 0 !important;
  }
  .login-left-side h2 {
    font-size: 20px;
    text-align: center;
  }
  .login-left-side h3 {
    font-size: 20px;
    margin-bottom: 0;
    text-align: center;
  }
  img.login-logo-icon.img-fluid {
    width: 30px;
  }
  .btn-primary {
    padding: 0.5rem 1.5rem;
  }
  img.mt-2.teka-login-logo {
    margin-top: 0 !important;
  }
  .card.mt-4.rounded-lg.register-sec {
    margin-top: 0 !important;
  }
}
</style>

export default {
  key: "advertisement",
  basePath: "/advertisement",
  exclude: false,
  components: [
    {
      name: "banner",
      component: () => import("./components/banner"),
    },
    {
      name: "bottom-banner",
      component: () => import("./components/bottom_banner"),
    },
    {
      name: "middle-offer",
      component: () => import("./components/middle_offer"),
    },
    {
      name: "offer",
      component: () => import("./components/offer"),
    },
    {
      name: "top-banner",
      component: () => import("./components/top_banner"),
    },
    {
      name: "top-banner-text",
      component: () => import("./components/top_banner_text"),
    },
    {
      name: "loading",
      component: () => import("./components/loading"),
    },
    {
      name: "healthians-top-banner",
      component: () => import("./components/healthians_top_banner"),
    },
    {
      name: "healthians-middle-banner",
      component: () => import("./components/healthians_middle_banner"),
    },
    {
      name: "healthians-bottom-banner",
      component: () => import("./components/healthians_bottom_banner"),
    },
    {
      name: "home-offer",
      component: () => import("./components/home_offer"),
    },
    {
      name: "home-offer-middle",
      component: () => import("./components/home_offer_middle"),
    },
    {
      name: "fashion-banner",
      component: () => import("./components/fashion-banner"),
    },
    {
      name: "fashion-middle",
      component: () => import("./components/fashion-middle"),
    },
  ],
};

import {
  fetchFashionCategory,
  //fetchFashionTrending,
  fetchFashionArrivals,
  fetchMarketList,
  fetchSpecificCategory,
  fetchProductType,
} from "./services";
import productDataFilter from "@/shared/productDataFilter";
import { structureItemData } from "/src/helpers/items";
export default {
  namespaced: true,
  state: {
    theme: [],
    category: [],
    trending: [],
    marketListData: [],
    marketDataList: {},
    marketList: [],
    isCategoryList: [],
    categoryListItems: [],
    productTypeList: [],
  },

  actions: {
    fetchAllMarket: async ({ commit }) => {
      try {
        const data = await fetchMarketList();
        commit("setMarketListData", data);
      } catch (e) {
        console.log(e);
      }
    },
    fetchProductType: async ({ commit }, category) => {
      try {
        const payload = { id: category[0]._id };
        const data = await fetchProductType(payload.id);
        const filteredData = data.filter((e) => {
          if (e.isProductType == true) {
            return {
              name: e.name,
              id: e._id,
            };
          }
        });
        commit("setProductType", filteredData);
      } catch (e) {
        console.log(e);
      }
    },
    fetchfashionMarketCategory: async ({ commit }, id) => {
      try {
        const payload = id;
        const data = await fetchFashionCategory(payload);
        const filteredCategoryData = data.filter((e) => e.isCategory == true);
        commit("setCategoryData", filteredCategoryData);
        const result = data.filter((e) => {
          if (e.isCategory == true) {
            return {
              id: e.id,
              name: e.name,
              index: e.index,
            };
          }
        });
        commit("setisCategoryList", result);
      } catch (e) {
        console.log(e);
      }
    },
    // fetchfashionTrending: async ({ commit }, marketName) => {
    //   try {
    //     const payload = {
    //       city: "Hyderabad",
    //       market: marketName,
    //     };

    //     const data = await fetchFashionTrending(payload);
    //     commit("setTrendingData", data);
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },

    fetchSearchResult: async ({ commit }, payload) => {
      try {
        const data = await fetchSpecificCategory(payload);
        const result = data.data.hits.hits.map((each) => {
          return {
            ...each._source,
            _id: each._id,
          };
        });
        let resultFinal = await productDataFilter.methods.filterProductData(
          result
        );
        commit("setSpecificCategoryData", resultFinal);
      } catch (e) {
        console.log(e);
      }
    },
    fetchSpecificCategory: async ({ commit }, payload) => {
      try {
        const data = await fetchSpecificCategory(payload);
        const result = data.data.hits.hits.map((each) => {
          return {
            ...each._source,
            _id: each._id,
          };
        });
        let resultFinal = await productDataFilter.methods.filterProductData(
          result
        );
        commit("setSpecificCategoryData", resultFinal);
      } catch (e) {
        console.log(e);
      }
    },
    fetchfashionArrivals: async ({ state, commit }, location_data) => {
      try {
        const marketDataList = {};

        const marketData = state.isCategoryList.map(async (e) => {
          const payload = {
            location: {
              lat: location_data.Lat,
              lon: location_data.Lng,
            },
            distance: 1000,
            category:
              e.name == "Men's Fashion"
                ? "Men's"
                : "" || e.name == "Women Fashion"
                ? "Women"
                : "" || e.name,
          };
          const data = await fetchFashionArrivals(payload);

          //   const idObj = { name: e.name, id: e._id };
          //   commit("setcatologId", idObj);

          marketDataList[e.name] = structureItemData(data);
          return;
        });
        await Promise.all(marketData);
        commit("setArrivalsData", marketDataList);
      } catch (e) {
        console.log(e);
      }
    },

    sortProducts: (context, payload) => {
      context.commit("sortProducts", payload);
    },
  },
  mutations: {
    // updateTheme(state, payload) {
    //   state.theme = payload;
    // },
    sortProducts: (state, payload) => {
      if (payload === "a-z") {
        state.categoryListItems.sort(function (a, b) {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          } else if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      } else if (payload === "z-a") {
        state.categoryListItems.sort(function (a, b) {
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return -1;
          } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      } else if (payload === "low") {
        state.categoryListItems.sort(function (a, b) {
          return +a.Price - +b.Price;
        });
      } else if (payload === "high") {
        state.categoryListItems.sort(function (a, b) {
          return +b.Price - +a.Price;
        });
      }
    },
    setProductType: (state, data) => {
      state.productTypeList = data;
    },
    setCategoryData: (state, data) => {
      state.category = data;
    },
    setTrendingData: (state, data) => {
      state.trending = data;
    },
    setArrivalsData: (state, data) => {
      state.marketDataList = data;
    },
    setMarketListData: (state, data) => {
      state.marketList = data;
    },
    setisCategoryList: (state, data) => {
      state.isCategoryList = data;
    },
    setSpecificCategoryData: (state, data) => {
      state.categoryListItems = data;
    },
  },
};

import moduleConfig from "./bill_payment.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./bill_payment.module"),
    children: [
      {
        path: "/bill_payment/:id/:category",
        name: "bill_payment",
        component: () => import("./views/mobile_recharge"),
      },
      {
        path: "/bill_payment/:category",
        name: "Mobile Recharge",
        component: () => import("./views/mobile_recharge"),
      },
      {
        path: "/bill_payment/:category",
        name: "Electricity Bill",
        component: () => import("./views/electricity-bill"),
      },
      //   {
      //     path: "/bill_payment/dth-payment/:id/:category",
      //     name: "dth-payment",
      //     component: () => import("./views/dth-payment"),
      //   },
      {
        path: "/bill_payment/view-plans",
        name: "view-plans",
        component: () => import("./views/view-plans"),
      },
      {
        path: "/bill_payment/:category",
        name: "DTH Payment",
        component: () => import("./views/dth-recharge"),
      },
      {
        path: "/bill_payment/dth-pay/:name/:ca_id/:id/:number/:category",
        name: "dth-pay",
        component: () => import("./views/dth-pay"),
      },
      {
        path: "/checkout-billpayment/:name/:id/:market_id/:number/:category",
        name: "checkout-billpayment",
        component: () => import("./views/checkout-billpayment"),
      },
      {
        path: "/service-checkout/:name/:ma_id/:id/:number/:category",
        name: "service-checkout",
        component: () => import("./views/service-checkout"),
      },
      {
        path: "/dth-checkout/:name/:m_id/:id/:number/:category",
        name: "dth-checkout",
        component: () => import("./views/dth-checkout"),
      },
      {
        path: "/payment/:category",
        name: "payment",
        component: () => import("./views/payment"),
      },
      {
        path: "/mobile-prepaid/:operator/:mobileNumber/:circle",
        name: "mobile-prepaid",
        component: () => import("./views/mobile-prepaid"),
      },

      {
        path: "/bill_payment/mobile_recharge",
        name: "rechargeAmount",
        component: () => import("./views/mobile_recharge"),
      },
      {
        path: "/checkout-postpaid/:operator/:customerNumber",
        name: "checkout-postpaid",
        component: () => import("./views/checkout-postpaid"),
      },
    ],
  },
];

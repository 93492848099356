import vue from "vue";

export const fetchFashionCategory = async (id) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/tree-public?market=${id}`
  );
  return res.data.response;
};

export const fetchMarketList = async () => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/filter?isMarket=y`
  );
  return res.data.response;
};

// export const fetchFashionTrending = async (payload) => {
//   const res = await vue.axios.post(`/home/list/trending`, payload);
//   return res.data.response;
// };

export const fetchFashionArrivals = async (payload) => {
  const res = await vue.axios.post("/home/v2/list/new-arrivals", payload);
  return res.data.response;
};

export const fetchSpecificCategory = async (payload) => {
  const res = await vue.axios.post(`/search/v2/search`, payload);
  return res;
};

export const fetchProductType = async (categoryId) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/filter?parent=${categoryId}`
  );
  return res.data.response;
};

import { fetchCategoryItemsList } from "./services";
import productDataFilter from "@/shared/productDataFilter";

export default {
  namespaced: true,
  state: {
    categoryitemList: [],
  },

  actions: {
    getCategoryItemsList: async (context, payload) => {
      try {
        const data = await fetchCategoryItemsList(payload);
        let resultFinal = await productDataFilter.methods.filterProductData(
          data
        );
        context.commit("setcategoryItemList", resultFinal);
      } catch (e) {
        console.log(e);
      }
    },
    sortProducts: (context, payload) => {
      context.commit("sortProducts", payload);
    },
    search: (context, payload) => {
      context.commit("search", payload);
    },
    filterItemFromProductType: (context, payload) => {
      context.commit("setfilteredProductData", payload);
    },
  },

  mutations: {
    setcategoryItemList(state, data) {
      state.categoryitemList = data;
    },
    search(state, payload) {
      const data = state.categoryitemList.filter(
        (eachItem) => eachItem.Name.toLowerCase() === payload.toLowerCase()
      );
      state.categoryitemList = data;
    },
    setfilteredProductData(state, payload) {
      const filteredData = state.categoryitemList.filter(
        (item) => item.catalog.name == payload
      );
      state.categoryitemList = filteredData;
    },
    sortProducts: (state, payload) => {
      if (payload === "a-z") {
        state.categoryitemList.sort(function (a, b) {
          if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return -1;
          } else if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      } else if (payload === "z-a") {
        state.categoryitemList.sort(function (a, b) {
          if (a.Name.toLowerCase() > b.Name.toLowerCase()) {
            return -1;
          } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {
            return 1;
          }
          return 0;
        });
      } else if (payload === "low") {
        state.categoryitemList.sort(function (a, b) {
          return +a.Price - +b.Price;
        });
      } else if (payload === "high") {
        state.categoryitemList.sort(function (a, b) {
          return +b.Price - +a.Price;
        });
      }
    },
  },
};

export default {
  methods: {
    filterProductData: (result) => {
      let finalArr = [];
      result.filter((ele) => {
        try {
          if (ele) {
            const tempProductInfo = {};
            let productInfo = {};
            for (const i in ele) {
              if (typeof ele[i] == "string") {
                tempProductInfo[i] = ele[i];
              }
            }
            if (ele.string_attributes) {
              for (const i of ele.string_attributes) {
                tempProductInfo[i.attribute_name] = i.attribute_value;
              }
            }
            if (ele.integer_attributes) {
              for (const i of ele.integer_attributes) {
                tempProductInfo[i.attribute_name] = i.attribute_value;
              }
            }
            if (ele.product_summary.images) {
              tempProductInfo.images = ele.product_summary.images;
            }
            delete ele.product_summary;
            delete ele.integer_attributes;
            delete ele.string_attributes;
            // if (!keepCatalogData) {
            //   delete ele.catalog;
            // }
            productInfo = {...tempProductInfo, ...ele};
            finalArr.push(productInfo);
          }
        } catch (err) {
          console.log(err);
        }
      });
      return finalArr;
    },
  },
};

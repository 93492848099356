import {
  fetchAddress,
  addNewAddress,
  editNewAddress,
  deleteAddressApi,
} from "./address.services";
import { getReverseGeoCode } from "../../shared/geocode";
export default {
  namespaced: true,
  state: {
    deliveryAddress: [],
    defaultAddress: {},
    addressLoading: true,
    deleteAddressLoading: "",
  },
  getters: {},
  actions: {
    async deleteAddress(context, payload) {
      context.state.deleteAddressLoading = payload;
      await deleteAddressApi(payload);
      let x = context.state.deliveryAddress.filter((el) => el._id != payload);
      context.commit("setDeliveryAddress", x);
      context.state.deleteAddressLoading = "";
    },
    async fetchDeliveryAddress(context) {
      context.state.addressLoading = true;
      let res = await fetchAddress();
      context.state.addressLoading = false;
      context.commit("setDeliveryAddress", res.data?.response?.addresses);
    },

    async adAddress(context, payload) {
      const data = await getReverseGeoCode(
        `${payload.houseNumber},${payload.street2},${payload.street1},${payload.city},${payload.state},${payload.country}`
      );
      if (data.data.status === "OK") {
        payload.location = {
          coordinates: [
            data.data.results[0].geometry.location.lat,
            data.data.results[0].geometry.location.lng,
          ],
        };
      }

      //   let payloadAddress = payload;
      payload.contacts = [];
      payload.contacts.push({
        value: payload.phoneNumber,
        contactType: "PRIMARY_PHONE",
      });

      payload.contacts.push({
        value: payload.email,
        contactType: "PRIMARY_EMAIL",
      });
      delete payload.email;
      delete payload.phoneNumber;
      const result = await addNewAddress({
        address: payload,
      });
      if (result.status == 200)
        context.commit("setNewAddedAddress", result.data.response.address);
      else return result;
    },
    async editAddress(context, payload) {
      const data = await getReverseGeoCode(
        `${payload.houseNumber},${payload.street2},${payload.street1},${payload.city},${payload.state},${payload.country}`
      );
      if (data.data.status === "OK") {
        payload.location = {
          coordinates: [
            data.data.results[0].geometry.location.lat,
            data.data.results[0].geometry.location.lng,
          ],
        };
      }

      //   let payloadAddress = payload;
      payload.contacts = [];
      payload.contacts.push({
        value: payload.phoneNumber,
        contactType: "PRIMARY_PHONE",
      });

      payload.contacts.push({
        value: payload.email,
        contactType: "PRIMARY_EMAIL",
      });
      let finalPayload = {
        id: payload.id,
      };
      delete payload.email;
      delete payload.phoneNumber;
      delete payload.id;

      finalPayload.data = {
        address: payload,
      };
      const result = await editNewAddress(finalPayload);
      if (result.status == 200) context.dispatch("fetchDeliveryAddress");
      else return result;
    },
  },
  mutations: {
    updateDefaultData(state, payload) {
      sessionStorage.setItem("updatedAddress", JSON.stringify(payload));
      state.defaultAddress = payload;
    },

    setNewAddedAddress(state, payload) {
      state.defaultAddress = payload;
      state.deliveryAddress.push(payload);
    },
    async setDeliveryAddress(state, payload) {
      state.deliveryAddress = payload;
      if (Array.isArray(payload) && payload.length == 0) {
        state.defaultAddress = {};
      } else if (Array.isArray(payload) && payload.length == 1) {
        state.defaultAddress = state.deliveryAddress[0];
      } else if (Array.isArray(payload) && payload.length > 1) {
        const defaultAddress = state.deliveryAddress.filter((x) => x.isDefault);
        state.defaultAddress = defaultAddress[0];
      }
    },
  },
};

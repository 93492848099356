import moduleConfig from "./fashionMarket.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./fashionMarket.module"),

    children: [
      {
        path: "fashion/:market",
        name: "fashionMarket",
        component: () => import("./views/fashionMarket.vue"),
      },
      {
        path: "fashionListing/:data",
        name: "category-listing",
        component: () => import("./views/fashionListing.vue"),
      },
      {
        path: ":data",
        name: "fashion-detail-view",
        component: () => import("./views/fashionMarketDetail.vue"),
      },
    ],
  },
];

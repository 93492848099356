export default {
  namespaced: true,
  state: {
    themeColor: {},
    market: "",
  },
  actions: {},
  mutations: {
    updateTheme(state, payload) {
      state.themeColor = payload;
    },
    updateName(state, payload) {
      state.market = payload;
    },
  },
};

import vue from "vue";

export const updateBankDetails = async (payload) => {
  const res = await vue.axios.post(
    "/payments/payout/cashfree/add-beneficiary",
    payload
  );
  return res;
};
export const verifyUpi = async (payload) => {
  const res = await vue.axios.post(
    "/payments/payout/cashfree/upi-verification",
    payload
  );
  return res;
};
export const fetchBankDetails = async (payload) =>
  await vue.axios.get(`/payments/payout/cashfree/get-beneficiary/${payload}`);

export const removeBankDetails = async (payload) =>
  await vue.axios.delete(
    `/payments/payout/cashfree/remove-beneficiary/${payload}`
  );

export const updateProfile = async (payload) =>
  await vue.axios.post("/cap/users/customer/update-profile", payload);

export const customerDetail = async (payload) =>
  await vue.axios.get(`/cap/users/customer/detail/${payload}`);

export const resetPassword = async (payload) => {
  await vue.axios.post("/cap/users/customer/update-password", payload);
};

export const getOrderList = async (payload) =>
  await vue.axios.get(
    `/order/api/v1/get/listbycustomer/${payload.id}?limit=${
      payload.limit
    }&type=${payload.type}&isMaster=${true}`
  );

export const getServiceOrderList = async (payload) =>
  await vue.axios.get(
    `/order/api/v1/get/listbycustomer/${payload.id}?limit=${
      payload.limit
    }&type=${payload.type}&isMaster=${true}`
  );

export const getInStoreOrderList = async (payload) =>
  await vue.axios.get(
    `/order/api/v1/get/listbycustomer/${payload.id}?limit=${
      payload.limit
    }&type=${payload.type}&isMaster=${true}`
  );

export const getfaqData = async () => await vue.axios.get(`/faq/list-faq`);

export const fetchAddress = async () =>
  await vue.axios.get("cap/users/customer/list-address");

export const getSupportTicketData = async () =>
  await vue.axios.get(`/servicerequest/ticket/list`);

export const getSupportTicketDetail = async (payload) =>
  await vue.axios.get(
    `/servicerequest/ticket/detail/${payload.ticketId}?tenant=${payload.tenantId}`
  );

export const getWalletData = async (payload) =>
  await vue.axios.get(
    `/wallet/wallet/history?from=${payload.startDate}&to=${payload.endDate}`
  );

export const getServiceRequestCategoryList = async () =>
  await vue.axios.get(`/servicerequest/category/list`);

export const createSupportTicketComment = async (payloadData) =>
  await vue.axios.post(
    `/servicerequest/ticket/postcomment/${payloadData.ticketId.ticket_Id}`,
    payloadData.payload
  );

export const createServiceRequest = async (payload) =>
  await vue.axios.post("/servicerequest/ticket/create", payload);

export const getWalletBalence = async (payload) =>
  await vue.axios.get(`/wallet/v2/wallet/balance?amountType=${payload}`);

export const getWalletHistoryList = async (payload) =>
  await vue.axios.get(
    `/wallet/v2/wallet/history?from=${payload.from}&to=${
      payload.to
    }&limit=${10}&page=${payload.page}&amountTypeId=${payload.amountType}`
  );

export const getResourceTypeData = async () => {
  const res = await vue.axios.get("/wallet/resource-master/list");
  return res;
};

export const getUploadImageToStorage = async (payload) => {
  const res = await vue.axios.post(`/ storage / image / upload`, payload);
  return res;
};

export const createReferrelLink = async (payload) => {
  let res = await vue.axios.post(`/referral`, payload);
  return res.data.url;
};

export const getCancelOrderStatus = async (id) => {
  await vue.axios.put("/order/api/v1/order/customer/cancel", {
    orderId: id,
  });
};

export const getSeller = async (number) => {
  let res = await vue.axios.get(
    `/cap/users/partner/get-detail-by-business-no/${number}`
  );
  return res.data.response;
};

export const getSellerDetails = async (id) => {
  let res = await vue.axios.get(
    `/catalog/api/item/filter?isTPT=y&provider=${id}`
  );
  return res.data.response;
};
export const fetchSellerData = async (payload) => {
  let res = await vue.axios.get(`/cap/users/partner/get-detail/${payload}`);
  return res.data.response;
};

export const createInstoreOrder = async (payload) => {
  let res = await vue.axios.post(`/order/api/v2/order/tpt/create`, payload);
  return res.data.response;
};

export const finalTransactionBenefits = async (payload) => {
  const res = await vue.axios.post(
    "/order/api/v1/order/reward-estimate",
    payload
  );
  return res;
};

export const getMyReferrals = async (userId) => {
  let res = await vue.axios.get(
    `/cap/users/customer/my-referrals?customer=${userId}`
  );
  return res.data.response;
};
export const getInvoice = async (orderId) => {
  let res = await vue.axios.get(`/order/packaging/invoice/${orderId}`);
  return res.data;
};

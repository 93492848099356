<template>
  <div id="app">
    <component :is="this.$router.currentRoute.meta.laylout || 'default-layout'">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import defaultLayout from "@/layouts/default";
import noservice from "@/layouts/noservice";
import login from "@/modules/auth/views/login";
import register from "@/modules/auth/views/register";

export default {
  name: "App",
  components: {
    defaultLayout,
    noservice,
    login,
    register
  },
  computed: {
    ...mapState({
      currentPosition: (state) => state.location.currentPosition,
    }),
    getCurrentLocation() {
      return this.currentPosition?.data?.City;
    },
  },
   mounted() {
    window.onpopstate = () => {
      if(
        window.localStorage.getItem("eparisheva:userInfo") !== null &&
        this.$route.path == "/"
      ) {
        this.$router.push("/");
      }
    };
  }
};
</script>

import {
  fetchElectricityCategory,
  fetchCheckoutDataMobile,
  fetchBill,
  fetchProductType,
  createOrdervalidateApi,
  fetchProduct,
  fetchCategoryData,
  createOrderApi,
  fetchDTHList,
  fetchMobileData,
  allPlans,
  viewBill,
  PostpaidOperatorid,
  finalTransactionBenefits,
  transactionBenefits,
} from "./services";

export default {
  namespaced: true,
  state: {
    operatorName: [],
    billData: [],
    paymentCheckoutData: {},
    categoryList: [],
    defaultAddress: {},
    productTypeData: [],
    DTHserviceList: [],
    recomendedData: [],
    availablePlans: [],
    productList: [],
    postpaidBill: [],
    postpaidId: [],
    benifits: [],
    order: [],
    transactionBenefits: [],
    electricityId: "",
    createOrdervalidateApi: [],
    checkoutLoading: true,
    finalCheckoutData: [],
    paymentMethodClick: "",
  },

  actions: {
    updatePaymentClick(context, payload) {
      context.state.paymentMethodClick = payload;
    },
    async createOrderAction(context, payload) {

      let ordr = await createOrderApi(payload);
      context.commit("setOrderId", ordr);
      return ordr;

    },

    async createOrdervalidateAction(context, payload) {

      let validate = await createOrdervalidateApi(payload);
      return validate;

    },

    async fetchcategory(context) {
      this.isLoading = true;
      const payload = "Electricity";
      let res = await fetchElectricityCategory(payload);
      this.isLoading = false;
      context.commit(
        "setOperatorName",
        res.map((each) => {
          return {
            id: each._id,
            operatorname: each.operatorname,
            operatorid: each.operatorid,
            viewBillRequired: each.viewBillRequired,
          };
        })
      );
    },

    async getCategory(context, payload) {
      let res = await fetchCategoryData(payload);
      context.commit("setMarketList", res);
    },

    async fetchProductTypeData(context, payload) {
      const data = await fetchProductType(payload);
      context.commit("setproductTypeData", data);
    },

    async fetchDTHData(context) {
      const payload = "DTH";
      const data = await fetchDTHList(payload);
      const newData = await data.map((e) => {
        return {
          icon: `https://static.mobikwik.com/appdata/operator_icons/op${e.operatorid}.png`,
          category: e.category,
          operatorid: e.operatorid,
          operatorname: e.operatorname,
          viewBillRequired: e.viewBillRequired,
          id: e._id,
        };
      });
      context.commit("setDTHserviceName", newData);
    },

    async fetchProductData(context, payload) {
      const data = await fetchProduct(payload);
      context.commit("setproductData", data.data.hits.hits);
    },
    async fetchBillApi(context, payload) {
      try {
        const data = await fetchBill(payload);
        context.commit("setBillData", data);
        return data;
      } catch (err) {
        console.log(err);
      }
    },

    async getCheckoutDataMobile(context, payload) {
      const res = await fetchCheckoutDataMobile(payload);

      context.commit("setCheckoutData", res);
    },

    async getMobile(context, payload) {
      this.isLoading = true;
      let res = await fetchMobileData(payload);

      context.commit("setRecomendedData", res.data.response);
      this.isLoading = false;
    },
    async getallPlans(context, payload) {
      let result = await allPlans(payload);
      //return result;
      context.commit("setAllPlan", result.data.response);
    },
    async postPaidViewBill(context, payload) {
      let result = await viewBill(payload);

      context.commit("setViewBill", result.data.response.data);
    },
    async getPostpaidOperatorid(context, payload) {
      let res = await PostpaidOperatorid(payload);

      context.commit("setPostpaidId", res.data.response);
    },
    async transactionBenefits(context, payload) {
      let res = await finalTransactionBenefits(payload);

      context.commit("setBenifits", res.data.response);
    },

    async getFinalCheckoutData(context) {
      const payload = {
        item: context.state.finalCheckoutData.data.items[0].productId,
        checkout: {
          type: context.state.paymentMethodClick,
          customerNo: this.state.auth.userInfo.customerNo,
          userEmail: this.state.auth.userInfo.authentication.email,
          userPhone: this.state.auth.userInfo.authentication.phone,
        },
        custom: {
          MobileNumber:
            context.state.finalCheckoutData.data.items[0].custom.MobileNumber,
          PlanCode: "",
          Amount: context.state.finalCheckoutData.data.items[0].custom.Amount,
          OperatorId:
            context.state.finalCheckoutData.data.items[0].custom.OperatorId,
          CircleId:
            context.state.finalCheckoutData.data.items[0].custom.CircleId,
          AdParams: {},
          market: {
            name: "Bill Payment",
            serviceProvider: "MOBIKWIK",
          },
          ServiceCategory:
            context.state.finalCheckoutData.data.items[0].custom
              .ServiceCategory,
          OperatorName:
            context.state.finalCheckoutData.data.items[0].custom.OperatorName,
        },
        isMobile: false,
      };
      const data = await fetchCheckoutDataMobile(payload);
      context.commit("checkoutData", data);
    },

    async getTransactionBenefits(context, payload) {
      const res = await transactionBenefits(payload);
      context.commit("setBenifits", res.data.response);
    },
  },
  mutations: {
    checkoutData(state, data) {
      state.finalCheckoutData = data;
    },
    setOrderId(state, data) {
      state.orderId = data.data?.response?.orderId;
    },
    setPaymentCheckoutData(state, payload) {
      state.paymentCheckoutData = payload;
    },
    setOperatorName(state, data) {
      state.operatorName = data;
    },
    setEleId(state, data) {
      state.electricityId = data;
    },
    setDthId(state, data) {
      state.dthId = data;
    },
    setMarketList(state, data) {
      state.categoryList = data.filter((e) => e.isCategory == true);
      state.productList = data.filter((e) => e.isProductType == true);
    },
    setBillData(state, data) {
      state.billData = data.data.response;
    },
    setproductData(state, data) {
      state.productData = data;
    },
    setDTHserviceName(state, data) {
      state.DTHserviceList = data;
    },
    setproductTypeData(state, data) {
      state.productTypeData = data.data.response;
    },
    setcreateOrder(state, data) {
      state.order = data;
    },
    // setBenifits(state, data) {
    //   state.benifits = data;
    // },
    setCheckoutData(state, data) {
      state.finalCheckoutData = data;
    },
    setPostpaidId(state, data) {
      state.postpaidId = data;
    },
    setViewBill(state, data) {
      state.postpaidBill = data;
    },

    // setMarketList(state, data) {
    //   state.categoryList = data.filter((e) => e.isCategory == true);
    //   state.productList = data.filter((e) => e.isProductType == true);
    // },
    setRecomendedData(state, data) {
      state.recomendedData = data;
    },
    setAllPlan(state, data) {
      state.availablePlans = data;
    },
    // setCheckoutData(state, data) {
    //   state.electricityCheckoutData = data;
    // },
    setBenifits(state, data) {
      state.transactionBenefits = data;
    },
  },
};

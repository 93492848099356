import vue from "vue";

const uploadImageToStorage = async (payload) => {
  const res = await vue.axios.post(`/storage/image/upload`, payload);
  return res;
};

const fetchHealtCareCategory = async (marketId) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/filter?parent=${marketId}`
  );
  return res.data.response;
};
const getHealthProuct = async (payload) => {
  const res = await vue.axios.post(`/search/v2/search`, payload);
  return res.data.hits.hits;
};
const getHealthianSlot = async (payload) => {
  const res = await vue.axios.post(
    `checkout/healthians/checkout/timeslot-byzip`,
    payload
  );
  return res.data.response.data;
};
const getHealthianMembers = async (payload) => {
  const res = await vue.axios.get(
    `/cap/users/customer/child/list?parent=${payload}`
  );
  return res.data.response;
};
const getProductType = async (payload) => {
  const res = await vue.axios.get(
    `/catalog/api/catalog/read/catalog/tree-public?market=${payload}`
  );
  return res.data.response;
};
const fetchProductData = async (payload) => {
  const res = await vue.axios.post(`/search/category/search/`, payload);
  return res;
};
const fetchColorTheme = async (payload) => {
  const res = await vue.axios.get(`/catalog/api/catalog/read/theme/${payload}`);
  return res;
};
const addHealthianMembers = async (payload) => {
  const res = await vue.axios.post(`/cap/users/customer/child/create`, payload);
  return res;
};
const checkHealthService = async (payload) => {
  const res = await vue.axios.post(
    `/checkout/healthians/checkout/check-available-service`,
    payload
  );
  return res.data.response;
};
const fetchPackageListByZip = async (payload) => {
  const res = await vue.axios.post(
    `/checkout/healthians/checkout/product-list-byzip`,
    payload
  );
  return res.data.response.data;
};

const fetchAllItems = async (payload) => {
  const res = await vue.axios.post(`/home/list/all`, payload);
  return res.data;
};

const pharmacyStoreList = async (payload) => {
  const res = await vue.axios.post(`cap/users/partner/nearby?page=1`, payload);
  return res;
};

const listProductFeedback = async (productId) => {
  const res = await vue.axios.get(`feedback/product-review/list/${productId}`);
  return res.data.data;
};

const createQuote = async (payload) => {
  const res = await vue.axios.post(`/quote/api/v1/create-quote`, payload);
  return res;
};

const listQutableItem = async (payload) => {
  const res = await vue.axios.post(`/search/provider/search/items`, payload);
  return res;
};

export {
  uploadImageToStorage,
  pharmacyStoreList,
  fetchAllItems,
  fetchHealtCareCategory,
  listProductFeedback,
  createQuote,
  listQutableItem,
  fetchPackageListByZip,
  getHealthProuct,
  getHealthianSlot,
  addHealthianMembers,
  getHealthianMembers,
  getProductType,
  fetchColorTheme,
  fetchProductData,
  checkHealthService,
};

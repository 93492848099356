import moment from "moment";
import {
  updateBankDetails,
  verifyUpi,
  fetchBankDetails,
  removeBankDetails,
  updateProfile,
  resetPassword,
  getOrderList,
  getfaqData,
  getSellerDetails,
  getSupportTicketData,
  getSupportTicketDetail,
  getWalletData,
  fetchSellerData,
  getInStoreOrderList,
  getServiceRequestCategoryList,
  createServiceRequest,
  getServiceOrderList,
  createReferrelLink,
  createSupportTicketComment,
  getWalletBalence,
  getResourceTypeData,
  getCancelOrderStatus,
  getWalletHistoryList,
  customerDetail,
  createInstoreOrder,
  getSeller,
  getInvoice,
  finalTransactionBenefits,
  getUploadImageToStorage,
  getMyReferrals,
} from "./profile.services";

export default {
  namespaced: true,
  state: {
    myReferrals: [],
    orderList: [],
    transactionBenefits: [],
    serviceOrderList: [],
    faqData: {},
    orderDetailsData: {},
    supportTicketData: [],
    supportTicketDetail: [],
    walletData: [],
    walletHistoryData: [],
    resourceTypeData: [],
    serviceRequestCategoryList: [],
    cancelled: false,
    userData: [],
    bankData: [],
    sellerInfo: [],
    searchSeller: [],
    sellerDetail: [],
    InStoreOrderList: [],
    invoiceData: [],
    // isLoading: false
  },

  actions: {
    async getWalletBalenceSpecific(context, payload) {
      let res = await getWalletBalence(payload);
      return res;
    },
    async getTransactionBenefits(context, payload) {
      const res = await finalTransactionBenefits(payload);
      context.commit("setTransactionBenefits", res.data.response);
    },

    async fetchResourceTypeData(context, payload) {
      let res = await getResourceTypeData(payload);
      context.commit("setResourceTypeData", res.data.response);
    },
    async createInstore(_, payload) {
      let res = await createInstoreOrder(payload);
      return res;
    },
    async getCustomerData(context, payload) {
      let res = await customerDetail(payload);
      context.commit("setUserData", res.data.response);
    },
    async handleResetPassword(context, payload) {
      let res = await resetPassword(payload);
      return res;
    },
    async fetchShopDetails(context, id) {
      let res = await getSellerDetails(id);
      context.commit("setSellerDetailData", res);
    },
    async getWalletHistoryListData(context, payload) {
      let res = await getWalletHistoryList(payload);
      context.commit("setWalletHistoryData", res.data.response);
      return res;
    },
    async fetchOrderList(context) {
      let payload = {
        type: "PRODUCT",
        id: this.state.auth.userInfo._id,
        limit: 20,
      };
      let res = await getOrderList(payload);
      context.commit("setOrderList", res.data.response);
    },

    async fetchServiceOrderList(context) {
      let payload = {
        type: "SERVICE",
        id: this.state.auth.userInfo._id,
        limit: 20,
      };
      let res = await getServiceOrderList(payload);
      context.commit("setServiceOrderList", res.data.response);
    },
    async fetchInStoreOrderList(context) {
      let payload = {
        type: "IN-STORE",
        id: this.state.auth.userInfo._id,
        limit: 20,
      };
      let res = await getInStoreOrderList(payload);
      context.commit("setInStoreOrderList", res.data.response);
    },
    async fetchSellerDetails(context, payload) {
      let res = await fetchSellerData(payload);
      context.commit("setSellerInfo", res);
    },
    async getInvoiceAction(context, payload) {
      let res = await getInvoice(payload);
      context.commit("setInvoiceData", res);
    },
    async updateProfileAction(context, payload) {
      let res = await updateProfile(payload);
      return res;
    },
    async verifyUpiDetails(context, payload) {
      let res = await verifyUpi(payload);
      return res;
    },
    async updateAccountDetails(_, payload) {
      let res = await updateBankDetails(payload);
      return res;
    },
    async getAccountDetails(context, payload) {
      let res = await fetchBankDetails(payload);
      context.commit("setBankData", res.data.data.data);
      return res;
    },
    async deleteBankDetails(context, payload) {
      await removeBankDetails(payload);
      context.commit("deleteAccount");
    },
    getOrderDetailsData(context, payload) {
      context.commit("setOrderDetailsData", payload);
    },

    async fetchFaqData(context) {
      this.isLoading = true;
      let res = await getfaqData();
      this.isLoading = false;
      context.commit(
        "setFaqData",
        res.data.data.map((each) => each.items)
      );
    },
    async fetchSeller(context, number) {
      let res = await getSeller(number);
      this.isLoading = false;
      context.commit("setSeller", res);
    },
    async fetchCancelOrderData(context, orderId) {
      try {
        if (orderId) {
          await getCancelOrderStatus(orderId)
            .then((res) => {
              context.state.orderDetailsData.orderStatus = "Cancelled";
              context.state.orderDetailsData.reportStatus = "Cancelled";
              context.state.cancelled = true;
              console.log(res);
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async fetchSupportTicketData(context) {
      this.isLoading = true;
      let res = await getSupportTicketData();
      this.isLoading = false;
      context.commit("setSupportTicketData", res.data.data);
    },

    async fetchSupportTicketDetail(context, payload) {
      this.isLoading = true;
      let res = await getSupportTicketDetail(payload);
      this.isLoading = false;
      context.commit("setSupportTicketDetail", res.data.data);
    },

    async fetchWalletData(context, payload) {
      this.isLoading = true;
      let res = await getWalletData(payload);
      this.isLoading = false;
      context.commit("setWalletData", res.data.response);
    },

    async fetchServiceRequestCategoryList(context) {
      this.isLoading = true;
      let res = await getServiceRequestCategoryList();
      this.isLoading = false;
      context.commit("setServiceRequestCategoryList", res.data.data);
    },

    async createServiceRequestTicket(context, payload) {
      await createServiceRequest(payload);
    },

    async supportTicketComment(context, payloadData) {
      await createSupportTicketComment(payloadData);
    },

    async createReferrel(context, payload) {
      let res = await createReferrelLink(payload);
      return res;
    },

    async fetchMyReferrals(context, userId) {
      let res = await getMyReferrals(userId);
      context.commit("setReferralData", res);
    },

    uploadImageToStorage: async (_, payload) => {
      return getUploadImageToStorage(payload);
    },
  },

  mutations: {
    setReferralData(state, data) {
      state.myReferrals = data;
    },
    setSeller(state, data) {
      state.searchSeller = data;
    },
    setInStoreOrderList(state, payload) {
      state.InStoreOrderList = payload;
    },
    setTransactionBenefits(state, payload) {
      state.transactionBenefits = payload;
    },
    setSellerDetailData(state, payload) {
      state.sellerDetail = payload;
    },
    setBankData(state, payload) {
      state.bankData = payload;
    },
    setInvoiceData(state, payload) {
      state.invoiceData = payload;
    },
    deleteAccount(state) {
      state.bankData = [];
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    setSellerInfo(state, payload) {
      state.sellerInfo = payload;
    },
    setOrderDetailsData(state, payload) {
      state.orderDetailsData = payload;
    },
    setOrderList(state, payload) {
      state.orderList = payload;
    },
    setServiceOrderList(state, payload) {
      state.serviceOrderList = payload;
    },
    setFaqData(state, data) {
      state.faqData = data;
    },
    setSupportTicketData(state, data) {
      state.supportTicketData = data;
    },
    setSupportTicketDetail(state, data) {
      state.supportTicketDetail = data;
    },
    setWalletData(state, data) {
      state.walletData = data;
    },
    setWalletHistoryData(state, data) {
      // const items = [];
      data.forEach((element) => {
        state.walletHistoryData.push({
          Date: moment(element.createdAt).format("DD/MM/YYYY"),
          Type: element.amount < 0 ? "Debit" : "Credit",
          Description: element.description,
          Amount: `₹ ${element.amount}`,
        });
      });
      // state.walletHistoryData = items;
    },
    setResourceTypeData(state, data) {
      state.resourceTypeData = data;
    },
    setServiceRequestCategoryList(state, data) {
      state.serviceRequestCategoryList = data;
    },
  },
};

import moduleConfig from "./cancellation_and_refund_policies.config";

export default [
    {
        path: moduleConfig.basePath,
        component: () => import("./cancellation_and_refund_policies.module"),
        children: [
            {
                path: '/',
                name: 'cancellation_and_refund_policies',
                component: () => import("./views/cancellation_and_refund_policies"),
            },
        ],
    },
];

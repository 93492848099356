import moduleConfig from "./return_refund_policy.config";

export default [
  {
    path: moduleConfig.basePath,
    component: () => import("./return_refund_policy.module"),
    children: [
      {
        path: '/',
        name: 'return_refund_policy',
        component: () => import("./views/return_refund_policy"),
      },
      {
        path: '/teka_delivery_policy',
        name: 'teka_delivery_policy',
        component: () => import("./views/teka_delivery_policy"),
      },
      {
        path: '/how_it_works',
        name: 'how_it_works',
        component: () => import("./views/how_it_works"),
      },
    ],
  },
];

import {
  fetchHealtCareCategory,
  fetchAllItems,
  pharmacyStoreList,
  listProductFeedback,
  createQuote,
  listQutableItem,
  uploadImageToStorage,
  fetchPackageListByZip,
  getHealthProuct,
  getHealthianSlot,
  addHealthianMembers,
  getHealthianMembers,
  getProductType,
  fetchColorTheme,
  fetchProductData,
  checkHealthService,
} from "./services/index.js";
import { structureItemData } from "/src/helpers/items";
export default {
  namespaced: true,
  state: {
    healthCareCategoryList: [],
    specificPackageList: {},
    healthianSlotData: [],
    selectedSlotData: {},
    selectedMemberData: {},
    healthianMembers: [],
    itemSuggestions: [],
    healthMarketRouterName: "",
    newArrivals: [],
    pharmacyStore: [],
    defaultLocation: {
      City: "Hyderabad",
      Country: "India",
      State: "Telangana",
      zip: "500004",
    },
    productFeedbackList: [],
    healthProducts: [],
    isServiceAvailable: false,
    healthCategoryName: "",
    productTypeData: {},
    productData: {},
  },

  getters: {
    getProductFeedbackData(state) {
      return (id) => state.productFeedbackList[id] || {};
    },
  },

  actions: {
    fetchProductFeedback: async (
      { commit, state },
      payload,
      refresh = false
    ) => {
      try {
        if (!state.productFeedbackList[payload._id] || refresh) {
          let result = await listProductFeedback(payload.catalogId);
          commit("setProductFeedback", {
            key: payload._id,
            value: result?.[0],
          });
        }
      } catch (e) {
        console.log(e);
      }
    },

    uploadImageToStorage: async (_, payload) => {
      return uploadImageToStorage(payload);
    },

    createQuote: async (_, payload) => {
      return createQuote(payload);
    },

    listQutableItem: async (_, payload) => {
      return listQutableItem(payload);
    },

    fetchPharmacyStore: async ({ commit }, payload) => {
      commit("setPharmacyStore", []);
      const data = await pharmacyStoreList(payload);
      commit("setPharmacyStore", data.data.response);
    },

    fetchHealthCareCategoryData: async (
      { commit, state },
      id,
      refresh = false
    ) => {
      try {
        if (!state.healthCareCategoryList || refresh) {
          commit("setHealthCareCategoryList", []);
        }
        const data = await fetchHealtCareCategory(id);
        const result = data;
        commit("setHealthCareCategoryList", result);
      } catch (e) {
        //
      }
    },
    getPackageByZip: async ({ commit }, payload) => {
      try {
        commit("setPacakgeItemsList", []);
        const data = await fetchPackageListByZip(payload);
        const result = data;
        commit("setPacakgeItemsList", result);
      } catch (e) {
        //
      }
    },
    fetchTimeSlotsAction: async (
      { commit, state },
      payload,
      refresh = false
    ) => {
      try {
        if (!state.healthianSlotData || refresh) {
          commit("setHealthianSlotData", []);
        }
        const data = await getHealthianSlot(payload);
        commit("setHealthianSlotData", data);
      } catch (e) {
        //
      }
    },
    healthianSlotDataAction: async (context, payload) => {
      context.commit("setSelectedSlotData", payload);
    },
    selectMemberAction: async (context, payload) => {
      context.commit("setSelectedMemberData", payload);
    },
    fetchHealthianMembers: async (context, payload) => {
      try {
        const data = await getHealthianMembers(payload);
        context.commit("setHealthianMember", data);
      } catch (e) {
        //
      }
    },
    getProductTypeAction: async (context, payload) => {
      try {
        const data = await getProductType(payload);
        context.commit("setProductType", data);
      } catch (e) {
        //
      }
    },
    getMarketColorTheme: async (context, payload) => {
      try {
        const data = await fetchColorTheme(payload);
        context.commit("setColorTheme", data);
      } catch (e) {
        //
      }
    },
    getProductData: async (context, payload) => {
      try {
        const data = await fetchProductData(payload);
        context.commit("setProductData", data.data.hits.hits);
      } catch (e) {
        //
      }
    },
    addMembersAction: async (_, payload) => {
      try {
        const data = await addHealthianMembers(payload);
        console.log(data);
      } catch (e) {
        //
      }
    },
    fetchHealthProductAction: async (
      { commit, state },
      payload,
      refresh = false
    ) => {
      try {
        if (!state.healthProducts || refresh) {
          commit("setHealthProducts", []);
        }
        const data = await getHealthProuct(payload);
        commit("setHealthProducts", data);
      } catch (e) {
        //
      }
    },
    fetchAllItems: async ({ commit, state }, market, refresh = false) => {
      try {
        if (
          (!state.itemSuggestions.length && !state.newArrivals.length) ||
          refresh
        ) {
          commit("setState", {
            itemSuggestions: [],
            newArrivals: [],
            isLoading: true,
          });
          const result = await fetchAllItems({
            city: [state.defaultLocation.City],
            market: [market],
            page: { page: 1, itemsPerPage: 20 },
          });
          commit("setState", {
            itemSuggestions: structureItemData(result.data.itemSuggestions),
            newArrivals: structureItemData(result.data.newArrivals),
            isLoading: false,
          });
        }
      } catch (e) {
        //
      }
    },

    checkHealthServiceAction: async (context, payload) => {
      const res = await checkHealthService(payload);
      context.commit("setServiceAvailability", res);
    },
  },
  mutations: {
    setPharmacyStore(state, data) {
      state.pharmacyStore = data;
    },
    setProductFeedback: (state, data) => {
      state.productFeedbackList = {
        ...state.productFeedbackList,
        [data.key]: data.value,
      };
    },
    setHealthCareCategoryList: (state, data) => {
      const categoryData = data.map((e) => e.catalogLabel.en);
      state.healthCareCategoryList = categoryData;
      state.healthCategoryName = data[0].name;
      state.healthMarketRouterName = data[0].catalogLabel.en;
    },
    setPacakgeItemsList: (state, data) => {
      state.specificPackageList = data.slice(0, 12);
    },
    setServiceAvailability: (state, data) => {
      state.isServiceAvailable = data.status;
    },
    setHealthProducts: (state, data) => {
      state.healthProducts = data;
    },
    setHealthianSlotData: (state, data) => {
      state.healthianSlotData = data;
    },
    setSelectedSlotData: (state, data) => {
      state.selectedSlotData = data;
    },
    setSelectedMemberData: (state, data) => {
      state.selectedMemberData = data;
    },
    setHealthianMember: (state, data) => {
      state.healthianMembers = data;
    },
    setProductType: (state, data) => {
      state.productTypeData = data.find((e) => e.isProductType === true);
    },
    setProductData: (state, data) => {
      state.productData = data[0];
    },
    setState: (state, data) => {
      Object.keys(data).map((field) => {
        state[field] = data[field];
      });
    },
  },
};

import { banner } from "./services/index.js";

export default {
  namespaced: true,
  state: {
    topBanner: {},
    offer1: {},
    offer2: {},
    searchBanner: {},
    heathiansTop: {},
    heathiansMiddle: {},
    heathiansBottom: {},
    homeOffer1: {},
    homeOffer2: {},
    fashionBanners: {},
    middleBanner: {},
    isLoading: true,
  },
  getters: {},
  actions: {
    topBannerData: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.topBanner.url || refresh) {
          commit("setTopBanner", {});
          const data = await banner({
            placement: "TOP_HOME_BANNER_WEB",
          });
          commit("setTopBanner", data.response.data);
        }
      } catch (e) {
        //
      }
    },
    fashionBanner: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.fashionBanners.url || refresh) {
          commit("setFashionBanner", {});
          const data = await banner({
            // city: payload.currentPosition ? payload.currentPosition : "",
            placement: "MARKET_TOP",
            market: "Clothes & Fashion",
          });
          commit("setFashionBanner", data.response.data);
        }
      } catch (e) {
        //
      }
    },
    fashionMiddleBanner: async ({ commit, state }) => {
      try {
        if (!state.middleBanner.url) {
          const data = await banner({
            placement: "MARKET_MIDDLE",
            // city: payload.currentPosition ? payload.currentPosition : "",
            // market: "Clothes & Fashion",
          });
          commit("setFashionMiddleBanner", data.response.data);
        }
      } catch (e) {
        //
      }
    },
    offerData1: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.offer1.url || refresh) {
          commit("setOffer1", {});
          const data = await banner({
            placement: "EPARISHEVA_HOME_MIDDLE1",
          });
          commit("setOffer1", data.response);
        }
      } catch (e) {
        //
      }
    },
    offerData2: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.offer1.url || refresh) {
          commit("setOffer2", {});
          const data = await banner({
            placement: "EPARISHEVA_HOME_MIDDLE2",
          });
          commit("setOffer2", data.response);
        }
      } catch (e) {
        //
      }
    },
    searchBannerData: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.searchBanner.url || refresh) {
          commit("setSearchBanner", {});
          const data = await banner({
            placement: "SEARCH_TOP_WEB",
          });
          commit("setSearchBanner", data.response.data[0]);
        }
      } catch (e) {
        //
      }
    },
    healthiansWebTop: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.heathiansTop.url || refresh) {
          commit("setHealthiansTop", {});
          const data = await banner({
            placement: "HEALTHIANS_WEB_TOP",
          });
          commit("setHealthiansTop", data.response.data[0]);
        }
      } catch (e) {
        //
      }
    },
    healthiansWebMiddle: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.heathiansTop.url || refresh) {
          commit("setHealthiansMiddle", {});
          const data = await banner({
            placement: "HEALTHIANS_WEB_MIDDLE1",
          });
          commit("setHealthiansMiddle", data.response.data[0]);
        }
      } catch (e) {
        //
      }
    },
    healthiansWebBottom: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.heathiansTop.url || refresh) {
          commit("setHealthiansBottom", {});
          const data = await banner({
            placement: "HEALTHIANS_WEB_BUTTOM",
          });
          commit("setHealthiansBottom", data.response.data[0]);
        }
      } catch (e) {
        //
      }
    },
    homeScreenOffer1: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.homeOffer1.url || refresh) {
          commit("setHomeOffer1", {});
          const data = await banner({
            placement: "HOME_OFFER1",
          });
          commit("setHomeOffer1", data.response.data);
        }
      } catch (e) {
        //
      }
    },
    homeScreenOffer2: async ({ commit, state }, refresh = false) => {
      try {
        if (!state.homeOffer2.url || refresh) {
          commit("setHomeOffer1", {});
          const data = await banner({
            placement: "HOME_OFFER2",
          });
          commit("setHomeOffer2", data.response.data);
        }
      } catch (e) {
        //
      }
    },
  },
  mutations: {
    setFashionMiddleBanner(state, data) {
      state.middleBanner = data;
    },
    setFashionBanner(state, data) {
      state.fashionBanners = data;
    },
    setTopBanner(state, data) {
      state.topBanner = data;
    },
    setHomeOffer1(state, data) {
      state.homeOffer1 = data;
    },
    setHomeOffer2(state, data) {
      state.homeOffer2 = data;
    },
    setOffer1(state, data) {
      state.offer1 = data;
    },
    setOffer2(state, data) {
      state.offer2 = data;
    },
    setSearchBanner(state, data) {
      state.searchBanner = data;
    },
    setHealthiansTop(state, data) {
      state.heathiansTop = data;
    },
    setHealthiansMiddle(state, data) {
      state.heathiansMiddle = data;
    },
    setHealthiansBottom(state, data) {
      state.heathiansBottom = data;
    },
  },
};
